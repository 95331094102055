import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { DTOTakeoutOrder } from 'models/src/dto';
import React, { FC, useContext } from 'react';
import { TaxContext } from '../../../modules/general/tax';
import { Yen } from '../../general/parts/Yen';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  takeoutOrder: DTOTakeoutOrder;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 'max-content',
    maxHeight: '25rem',
  },
  optionName: {
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  remarks: {
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  oddTableRow: {
    background: '#dbdbdb',
  },
  evenTableRow: {
    background: '#F8F8F8',
  },
  boldText: { fontWeight: 700 },
  responsiveText: {
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  tinyText: {
    fontSize: '0.5rem',
    '@media (max-width: 1024px)': {
      fontSize: '0.3rem',
    },
  },
}));

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      background: '#8e8e8e',
      color: '#fff',
      fontWeight: 700,
      '@media (max-width: 1024px)': {
        fontSize: '0.8rem',
      },
    },
    body: {
      verticalAlign: 'top',
      fontWeight: 700,
      '@media (max-width: 1024px)': {
        fontSize: '0.8rem',
      },
    },
  })
)(TableCell);

const StyledLastTableCell = withStyles((theme) =>
  createStyles({
    body: {
      verticalAlign: 'top',
      fontWeight: 700,
      color: '#fff',
      borderBottom: 'none',
      '@media (max-width: 1024px)': {
        fontSize: '0.8rem',
      },
    },
  })
)(TableCell);

const StyledLastTableRow = withStyles((theme) =>
  createStyles({
    root: {
      background: '#8e8e8e',
      color: '#fff',
    },
  })
)(TableRow);

export const ReservationDetailDialogBody: FC<Props> = ({ className, takeoutOrder }) => {
  const classes = useStyles();

  const taxStore = useContext(TaxContext);

  const calculatedTotalPrice = takeoutOrder.takeoutOrderDetails.reduce((accumulator, currentValue) => {
    const totalProductPrice =
      (currentValue.productPrice +
        currentValue.takeoutOrderOptions.reduce((optionAccumulator, optionCurrentValue) => {
          return optionAccumulator + optionCurrentValue.optionPrice;
        }, 0)) *
      currentValue.count;
    return totalProductPrice > 0 ? totalProductPrice + accumulator : accumulator;
  }, 0);

  const totalPrice = calculatedTotalPrice > 0 ? calculatedTotalPrice : 0;

  const calculatedTakeoutOrder = {
    ...takeoutOrder,
    takeoutOrderDetails: takeoutOrder.takeoutOrderDetails.map((takeoutOrderDetail) => {
      const tmpCalculatedSubTotalPrice =
        (takeoutOrderDetail.productPrice +
          takeoutOrderDetail.takeoutOrderOptions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.optionPrice;
          }, 0)) *
        takeoutOrderDetail.count;
      const calculatedSubTotalPrice = tmpCalculatedSubTotalPrice > 0 ? tmpCalculatedSubTotalPrice : 0;

      return {
        ...takeoutOrderDetail,
        subTotalPrice: calculatedSubTotalPrice,
      };
    }),
    totalPrice: taxStore.state.tax
      ? Math.floor(new BigNumber(1 + taxStore.state.tax.reducedRate / 100).times(totalPrice).toNumber())
      : totalPrice,
  };

  return (
    <TableContainer className={classes.wrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>商品名</StyledTableCell>
            <StyledTableCell>オプション</StyledTableCell>
            <StyledTableCell align="right">数量</StyledTableCell>
            <StyledTableCell align="right">価格</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calculatedTakeoutOrder.takeoutOrderDetails.map((takeoutOrderDetail, orderDetailIndex) => {
            return (
              <>
                <TableRow
                  tabIndex={-1}
                  key={`takeout-order-detail-${takeoutOrder.takeoutCustomer.id}-${takeoutOrderDetail.id}`}
                  className={orderDetailIndex % 2 === 0 ? classes.evenTableRow : classes.oddTableRow}
                >
                  <StyledTableCell>{takeoutOrderDetail.productName}</StyledTableCell>
                  <StyledTableCell>
                    {takeoutOrderDetail.takeoutOrderOptions.map((takeoutOrderOption) => {
                      return (
                        <Typography
                          key={`takeout-order-option-${takeoutOrderOption.id}`}
                          className={`${classes.optionName} ${classes.boldText}`}
                        >
                          {`- ${takeoutOrderOption.optionName}`}
                        </Typography>
                      );
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="right">{takeoutOrderDetail.count}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Yen
                      price={takeoutOrderDetail.subTotalPrice}
                      tax={taxStore.state.tax?.reducedRate}
                      className={`${classes.responsiveText} ${classes.boldText}`}
                    />
                  </StyledTableCell>
                </TableRow>
                {takeoutOrderDetail.remarks && (
                  <TableRow
                    tabIndex={-1}
                    className={orderDetailIndex % 2 === 0 ? classes.evenTableRow : classes.oddTableRow}
                  >
                    <TableCell colSpan={4}>
                      <Typography className={`${classes.boldText} ${classes.remarks}`}>特記事項</Typography>
                      <Typography className={`${classes.boldText} ${classes.remarks}`}>
                        {takeoutOrderDetail.remarks}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
          <StyledLastTableRow tabIndex={-1}>
            <StyledLastTableCell colSpan={2}>合計</StyledLastTableCell>
            <StyledLastTableCell align="right">
              {takeoutOrder.takeoutOrderDetails?.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
              }, 0)}
            </StyledLastTableCell>
            <StyledLastTableCell align="right">
              <Yen
                price={calculatedTakeoutOrder.totalPrice}
                className={`${classes.responsiveText} ${classes.boldText}`}
              />
              <Typography className={`${classes.boldText} ${classes.tinyText}`}>(税込)</Typography>
            </StyledLastTableCell>
          </StyledLastTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
