import { makeStyles } from '@material-ui/core';
import { DTOTakeoutOrder, DTOTakeoutReceptionTimezone } from 'models';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { DISPLAY_INTERVAL_MINUTES } from '../../../constants/takeout/orders';
import { useComponentDidUpdate } from '../../../hooks/useComponentDidUpdate';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { useNewOrder } from '../../../hooks/useNewOrder';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { TaxContext, taxOperations } from '../../../modules/general/tax';
import { UIContext } from '../../../modules/general/ui';
import { ShopContext, shopOperations } from '../../../modules/takeout/shop';
import {
  takeoutOrderActions,
  TakeoutOrderContext,
  takeoutOrderOperations,
} from '../../../modules/takeout/takeoutOrder';
import { minutesUtil, toDatetimeStrFromDate, toNthLateMidnight } from '../../../utils/dateUtil';
import { UiMessage } from '../../general/parts/uiMessage';
import { IconSideMenu } from '../units/iconSideMenu';
import { CalendarMain } from './calendarMain';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: '#F8F8F8',
  },
  iconSideMenu: {
    width: '14rem',
    '@media (max-width: 1024px)': {
      width: 'max-content',
    },
  },
}));

const generateTermTimes = (startTime?: string, endTime?: string) => {
  // 開始と終了少なくともどちらか一方がない場合は空配列を返す
  if (!startTime || !endTime) {
    return [];
  }
  // 分に変換
  const startMinutes = minutesUtil.toMinutesFromTime(startTime);
  const endMinutes = minutesUtil.toMinutesFromTime(endTime);
  // 開始と終了が反転している場合は空配列を返す
  if (endMinutes < startMinutes) {
    return [];
  }

  // 表示間隔時間に寄せた時間(分)
  const modifiedStartMinutes = startMinutes - (startMinutes % DISPLAY_INTERVAL_MINUTES);
  const modifiedEndMinutes = endMinutes - (endMinutes % DISPLAY_INTERVAL_MINUTES);

  // 最初の表示分として1を加算している
  const displayTimesCount = (modifiedEndMinutes - modifiedStartMinutes) / DISPLAY_INTERVAL_MINUTES + 1;

  return (
    [...Array(displayTimesCount).keys()]
      // numは0からスタートする
      .map((num) => modifiedStartMinutes + num * DISPLAY_INTERVAL_MINUTES)
      .map((minutes) => minutesUtil.toTimeFromMinutes(minutes))
  );
};

const makeDisplayTimes = (selectingCalendarDate: Date, takeoutReceptionTimezones: DTOTakeoutReceptionTimezone[]) => {
  const selectingCalendarDateType = selectingCalendarDate.getDay();
  const selectingTimezone = takeoutReceptionTimezones.find(
    (timezone) => timezone.dayOfWeek.id === selectingCalendarDateType
  );

  if (!selectingTimezone) {
    return [];
  }
  const { lunchStartTime, lunchEndTime, dinnerStartTime, dinnerEndTime } = selectingTimezone;

  const lunchTerm = generateTermTimes(lunchStartTime, lunchEndTime);
  const dinnerTerm = generateTermTimes(dinnerStartTime, dinnerEndTime);
  // 合算と重複排除
  return Array.from(new Set([...lunchTerm, ...dinnerTerm]));
};

export const CalendarLayout: FC = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const authStore = useContext(AuthContext);
  const takeoutOrderStore = useContext(TakeoutOrderContext);
  const shopStore = useContext(ShopContext);
  const taxStore = useContext(TaxContext);

  const fetchTakeoutOrder = () => {
    const { selectingCalendarStartingDate } = takeoutOrderStore.state;
    takeoutOrderOperations.getTakeoutOrder(uiStore.dispatch, takeoutOrderStore.dispatch, {
      // FIXME: shopIdをstoreから取得する
      shopId: 1,
      // 1週間後の00時00分までのデータを取得する
      termStart: toDatetimeStrFromDate(toNthLateMidnight(selectingCalendarStartingDate)),
      termEnd: toDatetimeStrFromDate(toNthLateMidnight(selectingCalendarStartingDate, 7)),
    });
  };

  useMountEffect(() => {
    authOperations.checkLogin(authStore.dispatch);
    // FIXME: idをstoreから取得する
    shopOperations.getShop(uiStore.dispatch, shopStore.dispatch, { id: 1 });
    taxOperations.getTax(uiStore.dispatch, taxStore.dispatch);
    fetchTakeoutOrder();
  });

  const updateTakeoutOrder = (order: DTOTakeoutOrder) => {
    takeoutOrderStore.dispatch(takeoutOrderActions.addTakeoutOrder(order));
  };

  // 新しい注文が入った時の処理
  useNewOrder(updateTakeoutOrder);

  // 週の変更に対応
  useComponentDidUpdate(fetchTakeoutOrder, [takeoutOrderStore.state.selectingCalendarStartingDate]);

  const displayTimes = useMemo(
    () =>
      makeDisplayTimes(takeoutOrderStore.state.selectingCalendarDate, shopStore.state.shop.takeoutReceptionTimezones),
    [takeoutOrderStore.state.selectingCalendarDate, shopStore.state.shop.takeoutReceptionTimezones]
  );

  // 日付の変更に対応
  useEffect(() => {
    takeoutOrderStore.dispatch(
      takeoutOrderActions.selectDisplayTakeoutOrder(
        takeoutOrderStore.state.orders,
        takeoutOrderStore.state.selectingCalendarDate,
        displayTimes
      )
    );
  }, [takeoutOrderStore.state.orders, takeoutOrderStore.state.selectingCalendarDate, displayTimes]);

  // 本日の新着件数
  const newOrderCount = useMemo(() => takeoutOrderStore.state.todayOrders.filter((e) => e.status === '新着').length, [
    takeoutOrderStore.state.todayOrders,
  ]);

  if (!authStore.state.user) <></>;

  return (
    <div className={classes.wrapper}>
      <IconSideMenu className={classes.iconSideMenu} newOrderCount={newOrderCount} />
      <CalendarMain />
      <UiMessage />
    </div>
  );
};
