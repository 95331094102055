import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { ReservationCalendar } from '../units/reservationCalendar';
import { ReservationCalendarHeader } from '../units/reservationCalendarHeader';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // iconSideMenuのwidthを減算したwidthを設定
    width: 'calc(100% - 14rem)',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 2.5rem)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

export const CalendarMain: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ReservationCalendarHeader />
      <ReservationCalendar />
    </div>
  );
};
