import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { TakeoutOrderContext } from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  calendarDate: Date;
  onClick: (date: Date) => void;
};

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2rem 2rem 0 0',
    color: theme.palette.text.primary,
    background: '#e1e1e1',
    '&:hover': {
      backgroundColor: theme.iconSideMenu?.hover.background,
    },
    '@media (max-width: 1024px)': {
      borderRadius: '1.5rem 1.5rem 0 0',
    },
  },
  currentCalendarDate: {
    backgroundColor: theme.iconSideMenu?.hover.background,
  },
  responsiveText: {
    fontSize: '1rem',
    '@media (max-width: 896px)': {
      fontSize: '0.8rem',
    },
  },
}));

const WEEKCHARS = ['日', '月', '火', '水', '木', '金', '土'];

export const PickCalenderDateButton: FC<Props> = ({ className, calendarDate, onClick }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);

  const handleClick = () => {
    onClick(calendarDate);
  };

  return (
    <ButtonBase
      className={`${classes.content} ${
        takeoutOrderStore.state.selectingCalendarDate.getDate() === calendarDate.getDate() &&
        classes.currentCalendarDate
      }`}
      onClick={handleClick}
    >
      <Typography variant="h6" className={classes.responsiveText}>
        {`${calendarDate.getMonth() + 1}月${calendarDate.getDate()}日`}
      </Typography>
      <Typography variant="h6" className={classes.responsiveText}>{`(${WEEKCHARS[calendarDate.getDay()]})`}</Typography>
    </ButtonBase>
  );
};
