export const feachStatusColor = (statusName: string) => {
  switch (statusName) {
    case '新着':
      return '#ef7f74';
    case '未対応':
      return '#4087c7';
    case '準備中':
      return '#eba53a';
    case '準備完了':
      return '#5ab6a6';
    case 'お渡し済':
      return '#b0c02e';
    default:
      return '';
  }
};
