import { makeStyles } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { takeoutOrderActions, TakeoutOrderContext } from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';
import { PickCalenderDateButton } from './pickCalendarDateButton';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridGap: '5px',
    height: '100%',
  },
}));

export const PickCalenderDate: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);

  const createCalendarDate = (addValue: number) => {
    const date = new Date(takeoutOrderStore.state.selectingCalendarStartingDate);
    date.setDate(takeoutOrderStore.state.selectingCalendarStartingDate.getDate() + addValue);
    return date;
  };

  const createDisplayWeek = () => {
    return [
      createCalendarDate(0),
      createCalendarDate(1),
      createCalendarDate(2),
      createCalendarDate(3),
      createCalendarDate(4),
      createCalendarDate(5),
      createCalendarDate(6),
    ];
  };

  const [displayWeek, setDisplayWeek] = useState(createDisplayWeek);

  // 起算日の変更時に表示させる日付を再計算させる処理
  useEffect(() => {
    setDisplayWeek(createDisplayWeek);
  }, [takeoutOrderStore.state.selectingCalendarStartingDate]);

  const selectCalendarDate = (date: Date) => {
    takeoutOrderStore.dispatch(takeoutOrderActions.selectCalendarDate(date));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.grid}>
        {displayWeek.map((date) => (
          <PickCalenderDateButton
            key={`calendar-date-${date.getDate()}`}
            calendarDate={date}
            onClick={selectCalendarDate}
          />
        ))}
      </div>
    </div>
  );
};
