import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { DTOTakeoutOrder } from 'models';
import React, { FC } from 'react';
import { feachStatusColor } from '../../../utils/feachStatusColor';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  takeoutOrder: DTOTakeoutOrder;
  onClick: (order: DTOTakeoutOrder) => void;
};

const useStyles = makeStyles((theme) => ({
  reservationCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  reservationCardDetail: {
    width: 'calc(100% - 2rem)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0.5rem 1rem',
  },
  statusBatch: { width: '100%', borderRadius: '1rem' },
  statusName: {
    color: '#fff',
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  responsiveTextWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  orderNumber: {
    minWidth: 'max-content',
    '@media (min-width: 1024px)': {
      marginRight: '0.5em',
    },
  },
  text: {
    textAlign: 'left',
    wordBreak: 'break-all',
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  boldText: {
    fontWeight: 700,
  },
}));

export const ReservationCard: FC<Props> = ({ className, takeoutOrder, onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick(takeoutOrder);
  };

  return (
    <ButtonBase key={`reservation-card-${takeoutOrder.id}`} className={classes.reservationCard} onClick={handleClick}>
      <div className={classes.reservationCardDetail}>
        <div
          className={classes.statusBatch}
          style={
            takeoutOrder.isCanceled ? { background: '#8e8e8e' } : { background: feachStatusColor(takeoutOrder.status) }
          }
        >
          <Typography className={`${classes.statusName} ${classes.boldText}`}>
            {takeoutOrder.isCanceled ? 'キャンセル' : takeoutOrder.status}
          </Typography>
        </div>
        <Typography className={`${classes.boldText} ${classes.text}`}>
          {takeoutOrder.receptionDate.slice(-5)}
        </Typography>
        <div className={classes.responsiveTextWrapper}>
          <Typography className={`${classes.orderNumber} ${classes.text}`}>{takeoutOrder.orderNumber}</Typography>
          <Typography className={classes.text}>{`${takeoutOrder.takeoutCustomer.name} 様`}</Typography>
        </div>
        <Typography className={classes.text}>{`${takeoutOrder.takeoutOrderDetails.length}点`}</Typography>
      </div>
    </ButtonBase>
  );
};
