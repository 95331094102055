import { makeStyles, Paper, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DTOTakeoutOrder } from 'models';
import React, { FC, useContext, useState } from 'react';
import { UIContext } from '../../../modules/general/ui';
import { ShopContext } from '../../../modules/takeout/shop';
import {
  takeoutOrderActions,
  TakeoutOrderContext,
  takeoutOrderOperations,
} from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';
import { DisplayTime } from './displayTime';
import { ReservationCard } from './reservationCard';
import { ReservationDetailDialog } from './reservationDetailDialog';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '85%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      height: '77%',
    },
  },
  scroll: { maxHeight: '90%', width: '95%', overflowY: 'auto' },
  displayTimeWrapper: { display: 'flex' },
  topDisplayTimeContents: { width: '100%', borderTop: '1px solid #f3f3f3' },
  reservationCardList: { display: 'flex', flexWrap: 'wrap', width: '100%' },
  reservationCardWrapper: {
    display: 'flex',
    width: 'calc(100% / 5)',
    borderRight: '1px solid #f3f3f3',
    borderBottom: '1px solid #f3f3f3',
    boxSizing: 'border-box',
  },
  text: { fontSize: '2rem', fontWeight: 'bold' },
}));

export const ReservationSchedule: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const takeoutOrderStore = useContext(TakeoutOrderContext);

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const selectReservationCard = (order: DTOTakeoutOrder) => {
    takeoutOrderStore.dispatch(takeoutOrderActions.reservationCardClicked(order));
    if (order.status === '新着') {
      takeoutOrderOperations.updateStatus(uiStore.dispatch, takeoutOrderStore.dispatch, {
        id: order.id,
        status: '未対応',
      });
    }
    setOpen(true);
  };

  // orders.lengthが5で割り切れない場合のレイアウト調整用関数
  // orders.lengthと合計して5で割り切れる分のJSXオブジェクトを格納した配列を返却する
  const adjustmentData = (orders: DTOTakeoutOrder[], displayOrderIndex: number) => {
    const jsxItems: JSX.Element[] = [];

    if (orders.length < 5) {
      const topDisplayContent = (
        <div
          className={`${classes.reservationCardWrapper} ${displayOrderIndex === 0 && classes.topDisplayTimeContents}`}
        />
      );
      for (let i = orders.length; i < 5; i++) {
        jsxItems.push(topDisplayContent);
      }
    } else if (orders.length > 5) {
      const displayContent = <div className={classes.reservationCardWrapper} />;
      for (let n = orders.length % 5; n < 5; n++) {
        jsxItems.push(displayContent);
      }
    }
    return jsxItems;
  };

  const { displayOrders, selectingCalendarDate } = takeoutOrderStore.state;

  const shopStore = useContext(ShopContext);

  // 選択している日付と休業日が一致するか照合する
  const formatedSelectingCalenderDate: string = format(selectingCalendarDate, 'yyyy/MM/dd 00:00');
  const isHoliday = shopStore.state.shop.takeoutHolidays.some(({ date }) => date === formatedSelectingCalenderDate);

  // テイクアウト注文があるか確認する
  const hasTakeoutOrder = displayOrders.some((currentValue) => {
    return currentValue.takeoutOrders.length !== 0;
  });

  return (
    <Paper className={classes.wrapper}>
      {!displayOrders.length || (isHoliday && !hasTakeoutOrder) ? (
        <Typography className={classes.text}>営業日ではありません</Typography>
      ) : (
        <div className={classes.scroll}>
          {displayOrders.map((displayOrder, displayOrderIndex) => (
            <div
              key={`display-time-${selectingCalendarDate.toISOString()}-${displayOrder.time}`}
              className={classes.displayTimeWrapper}
            >
              <DisplayTime index={displayOrderIndex} displayOrder={displayOrder} />
              <div className={classes.reservationCardList}>
                {displayOrder.takeoutOrders.map((order, orderIndex) => (
                  <div
                    key={`takeout-order-number-${order.orderNumber}`}
                    className={`${classes.reservationCardWrapper} ${
                      displayOrderIndex === 0 && orderIndex < 5 && classes.topDisplayTimeContents
                    }`}
                  >
                    <ReservationCard takeoutOrder={order} onClick={selectReservationCard} />
                  </div>
                ))}
                {adjustmentData(displayOrder.takeoutOrders, displayOrderIndex).map((jsx) => jsx)}
              </div>
            </div>
          ))}
        </div>
      )}
      <ReservationDetailDialog open={open} onClose={onClose} />
    </Paper>
  );
};
