import { ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import { DTOTakeoutOrder } from 'models';
import React, { FC, useContext } from 'react';
import { UIContext } from '../../../modules/general/ui';
import { TakeoutOrderContext, takeoutOrderOperations } from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  takeoutOrder: DTOTakeoutOrder;
  anchor: (EventTarget & HTMLButtonElement) | null;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 10,
  },
  menuList: {
    padding: 0,
    outline: 0,
  },
}));

export const SelectStatusMenu: FC<Props> = ({ className, takeoutOrder, anchor, onClose }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);
  const uiStore = useContext(UIContext);

  const handleClick = (statusType: string) => {
    takeoutOrderOperations
      .updateStatus(uiStore.dispatch, takeoutOrderStore.dispatch, {
        id: takeoutOrder.id,
        status: statusType,
      })
      .then(() => {
        onClose();
      });
  };

  const statusTypes = ['未対応', '準備中', '準備完了', 'お渡し済'];

  return (
    <Popper open={!!anchor} anchorEl={anchor} role={undefined} transition disablePortal className={classes.popper}>
      {({ TransitionProps }) => (
        <Grow
          in={TransitionProps?.in}
          onEnter={TransitionProps?.onEnter}
          onExited={TransitionProps?.onExited}
          style={{ transformOrigin: 'center top' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList className={classes.menuList} id="status-menu-list-grow">
                {statusTypes.map((statusType) => (
                  <MenuItem
                    key={`select-status-menu-${statusType}`}
                    selected={takeoutOrder.status === statusType}
                    disabled={takeoutOrder.status === statusType}
                    onClick={() => handleClick(statusType)}
                  >
                    <Typography>{statusType}</Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
