import { makeStyles, Typography } from '@material-ui/core';
import { DTOTakeoutOrder } from 'models';
import React, { FC, useContext, useEffect, useState } from 'react';
import { TakeoutOrderContext } from '../../../modules/takeout/takeoutOrder';
import { feachStatusColor } from '../../../utils/feachStatusColor';
import { ClassName } from '../../general/props/classname';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '75%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  statusCountTitle: {
    width: 'max-content',
    marginRight: '1rem',
    color: theme.palette.text.primary,
    fontSize: '1rem',
    '@media (max-width: 1024px)': {
      marginRight: '0.5rem',
    },
    '@media (max-width: 896px)': {
      marginRight: '0.2rem',
      fontSize: '0.8rem',
    },
  },
  statusCountContentWrapper: { width: '75%', display: 'flex', flexWrap: 'wrap' },
  eachStatusCountWrapper: {
    width: 'calc((100% - 3rem) / 3)',
    marginRight: '1rem',
    display: 'flex',
    '@media (max-width: 1024px)': {
      width: 'calc((100% - 1.5rem) / 3)',
      marginRight: '0.5rem',
    },
    '@media (max-width: 896px)': {
      width: 'calc((100% - 0.6rem) / 3)',
      marginRight: '0.2rem',
    },
  },
  eachStatusCountTitle: {
    width: '50%',
    color: '#fff',
    textAlign: 'center',
    fontSize: '1rem',
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  eachStatusCount: {
    width: '50%',
    color: theme.palette.text.primary,
    background: '#fff',
    textAlign: 'center',
    fontSize: '1rem',
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  boldText: {
    fontWeight: 700,
  },
}));

export const StatusCount: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const [statusState, setStatusState] = useState([
    { name: '新着', count: 0 },
    { name: '未対応', count: 0 },
    { name: '準備中', count: 0 },
    { name: '準備完了', count: 0 },
    { name: 'お渡し済', count: 0 },
  ]);

  const takeoutOrderStore = useContext(TakeoutOrderContext);

  // 引数として受け取ったステータス名と同一ステータスの予約情報の数量を算出して、返却する関数
  const calcStatusCount = (takeoutOrders: DTOTakeoutOrder[], statusName: string): number => {
    return takeoutOrders.reduce((accumulator, takeoutOrder) => {
      return takeoutOrder.status === statusName && !takeoutOrder.isCanceled ? accumulator + 1 : accumulator;
    }, 0);
  };

  useEffect(() => {
    setStatusState(
      statusState.map((status) => {
        return {
          name: status.name,
          count: takeoutOrderStore.state.displayOrders.reduce((accumulator, data) => {
            return accumulator + calcStatusCount(data.takeoutOrders, status.name);
          }, 0),
        };
      })
    );
  }, [takeoutOrderStore.state.displayOrders]);

  return (
    <>
      <div className={classes.wrapper}>
        <Typography className={`${classes.statusCountTitle} ${classes.boldText}`} variant="h6">
          ステータス件数
        </Typography>
        <div className={classes.statusCountContentWrapper}>
          {statusState.map((status) => {
            return (
              <div key={`status-count-${status.name}`} className={classes.eachStatusCountWrapper}>
                <Typography
                  className={`${classes.eachStatusCountTitle} ${classes.boldText}`}
                  style={
                    `${feachStatusColor(status.name)}`
                      ? { background: feachStatusColor(status.name) }
                      : { background: '#8e8e8e' }
                  }
                  variant="h6"
                >
                  {status.name}
                </Typography>
                <Typography className={`${classes.eachStatusCount} ${classes.boldText}`} variant="h6">
                  {`${status.count}件`}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
