import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { TakeoutOrderContext } from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  type: 'back' | 'next';
  src: '*.png' | '*.jpg' | '*.gif' | '*.svg';
  text: string;
  onClick: (date: Date) => void;
};

const useStyles = makeStyles((theme) => ({
  button: { height: '100%', display: 'flex', alignItems: 'center' },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    margin: '0 0.5rem',
  },
  icon: { height: '60%' },
}));

export const ChangeDisplayWeekButton: FC<Props> = ({ className, type, src, text, onClick }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);

  const handleClick = () => {
    const newDate = new Date(takeoutOrderStore.state.selectingCalendarStartingDate);
    const calculationValue = type === 'next' ? 7 : -7;
    newDate.setDate(newDate.getDate() + calculationValue);
    onClick(newDate);
  };

  return (
    <ButtonBase className={classes.button} onClick={handleClick}>
      {type === 'back' && <img src={src} alt={`${text}アイコン`} className={classes.icon} />}
      <Typography className={classes.text}>{text}</Typography>
      {type === 'next' && <img src={src} alt={`${text}アイコン`} className={classes.icon} />}
    </ButtonBase>
  );
};
