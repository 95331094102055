import { Badge, makeStyles, Typography } from '@material-ui/core';
import { DTOTakeoutOrder } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  index: number;
  displayOrder: {
    time: string;
    takeoutOrders: DTOTakeoutOrder[];
  };
};

const useStyles = makeStyles((theme) => ({
  oddDisplayTime: {
    minHeight: '6.5rem',
    background: '#dbdbdb',
    padding: '1px 5px',
    textAlign: 'center',
    width: '2.7rem',
  },
  evenDisplayTime: {
    minHeight: '6.5rem',
    background: '#f3f3f3',
    padding: '1px 5px',
    textAlign: 'center',
    width: '2.7rem',
  },
  boldText: {
    fontWeight: 700,
    marginBottom: '10px',
  },
  responsiveText: {
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  customBadge: {
    backgroundColor: '#ef7f74',
    color: 'white',
  },
}));

export const DisplayTime: FC<Props> = ({ index, displayOrder }) => {
  const classes = useStyles();
  const newOrderCount = displayOrder.takeoutOrders.filter((e) => e.status === '新着').length;

  return (
    <div className={index % 2 === 0 ? classes.evenDisplayTime : classes.oddDisplayTime}>
      <Typography className={`${classes.boldText} ${classes.responsiveText}`}>{displayOrder.time}</Typography>
      <Badge badgeContent={newOrderCount} classes={{ badge: classes.customBadge }} />
    </div>
  );
};
