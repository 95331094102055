import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import backIcon from '../../../images/takeout/leftArrowCircleIcon.png';
import nextIcon from '../../../images/takeout/rightArrowCircleIcon.png';
import { takeoutOrderActions, TakeoutOrderContext } from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';
import { ChangeDisplayWeekButton } from './changeDisplayWeekButton';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: { width: '100%', height: '5%', display: 'flex', justifyContent: 'space-around', marginTop: '0.5rem' },
  currentDateButton: { height: '100%', display: 'flex', alignItems: 'center' },
  currentDateText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    margin: '0 0.5rem',
  },
  icon: { height: '60%' },
}));

export const ChangeDisplayWeek: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);

  const selectCalendarStartingDate = (date: Date) => {
    takeoutOrderStore.dispatch(takeoutOrderActions.selectCalendarStartingDate(date));
  };

  const initCalendarStartingDate = () => {
    takeoutOrderStore.dispatch(takeoutOrderActions.selectCalendarStartingDate(new Date()));
  };

  return (
    <div className={classes.wrapper}>
      <ChangeDisplayWeekButton
        type="back"
        src={backIcon}
        text="前の一週間を表示する"
        onClick={selectCalendarStartingDate}
      />
      <ButtonBase className={classes.currentDateButton} onClick={initCalendarStartingDate}>
        <Typography className={classes.currentDateText}>今日</Typography>
      </ButtonBase>
      <ChangeDisplayWeekButton
        type="next"
        src={nextIcon}
        text="次の一週間を表示する"
        onClick={selectCalendarStartingDate}
      />
    </div>
  );
};
