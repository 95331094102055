import { makeStyles, Typography } from '@material-ui/core';
import { DTOTakeoutOrder } from 'models';
import React, { FC, useContext, useState } from 'react';
import { UIContext } from '../../../modules/general/ui';
import { TakeoutOrderContext, takeoutOrderOperations } from '../../../modules/takeout/takeoutOrder';
import { feachStatusColor } from '../../../utils/feachStatusColor';
import { ClassName } from '../../general/props/classname';
import { RectangleButton } from './rectangleButton';
import { SelectStatusMenu } from './selectStatusMenu';

type Props = ClassName & {
  takeoutOrder: DTOTakeoutOrder;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '12rem',
    '@media (max-width: 1024px)': {
      height: '9rem',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#F8F8F8',
  },
  receptionDateWrapper: {
    width: 'calc(12rem * 0.85)',
    '@media (max-width: 1024px)': {
      width: 'calc(9rem * 0.85)',
    },
    maxWidth: '20%',
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '1rem',
  },
  receptionDateTitle: {
    marginBottom: '0.8rem',
    '@media (max-width: 1024px)': {
      fontSize: '1rem',
      marginBottom: '0.5rem',
    },
  },
  receptionDate: {
    '@media (max-width: 1024px)': {
      fontSize: '1rem',
    },
  },
  receptionTime: {
    '@media (max-width: 1024px)': {
      fontSize: '1.5rem',
    },
  },
  customerInfomationWrapper: {
    width: '55%',
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: '#fff',
    borderRadius: '1rem',
  },
  customerInfomation: {
    margin: '0 2rem',
  },
  customerInfomationText: {
    wordBreak: 'break-all',
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
  },
  buttonGroupWrapper: {
    width: '20%',
    height: '85%',
  },
  buttonGroup: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  statusButton: {
    width: '100%',
    height: '30%',
  },
  boldText: {
    fontWeight: 700,
  },
  letterSpacing: {
    letterSpacing: '0.1rem',
  },
}));

export const ReservationDetailDialogHead: FC<Props> = ({ className, takeoutOrder }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);
  const uiStore = useContext(UIContext);

  const [selectStatusMenuAnchor, setSelectStatusMenuAnchor] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const onOpenSelectStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectStatusMenuAnchor(event.currentTarget);
  };

  const onCloseSelectStatusMenu = () => {
    setSelectStatusMenuAnchor(null);
  };

  const toggleCancel = () => {
    takeoutOrderOperations.updateCancel(uiStore.dispatch, takeoutOrderStore.dispatch, {
      id: takeoutOrder.id,
      isCanceled: !takeoutOrder.isCanceled,
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.receptionDateWrapper}>
        <div>
          <Typography variant="h6" className={`${classes.receptionDateTitle} ${classes.boldText}`}>
            お受取日時
          </Typography>
          <Typography variant="h6" className={`${classes.receptionDate} ${classes.letterSpacing} ${classes.boldText}`}>
            {takeoutOrder.receptionDate.slice(0, 10)}
          </Typography>
          <Typography variant="h4" className={`${classes.receptionTime} ${classes.letterSpacing} ${classes.boldText}`}>
            {takeoutOrder.receptionDate.slice(-5)}
          </Typography>
        </div>
      </div>
      <div className={classes.customerInfomationWrapper}>
        <div className={classes.customerInfomation}>
          <Typography className={`${classes.customerInfomationText} ${classes.boldText} ${classes.letterSpacing}`}>
            注文番号:
            {takeoutOrder.orderNumber}
          </Typography>
          <Typography className={`${classes.customerInfomationText} ${classes.boldText} ${classes.letterSpacing}`}>
            氏名:
            {takeoutOrder.takeoutCustomer.name}
          </Typography>
          <Typography className={`${classes.customerInfomationText} ${classes.boldText} ${classes.letterSpacing}`}>
            カナ:
            {takeoutOrder.takeoutCustomer.furigana}
          </Typography>
          <Typography className={`${classes.customerInfomationText} ${classes.boldText} ${classes.letterSpacing}`}>
            電話番号:
            {takeoutOrder.takeoutCustomer.phoneNumber}
          </Typography>
          <Typography className={`${classes.customerInfomationText} ${classes.boldText} ${classes.letterSpacing}`}>
            メールアドレス:
            {takeoutOrder.takeoutCustomer.mail}
          </Typography>
        </div>
      </div>
      <div className={classes.buttonGroupWrapper}>
        <div className={classes.buttonGroup}>
          <RectangleButton
            className={classes.statusButton}
            colorPattern="default"
            color={takeoutOrder.isCanceled ? '#8e8e8e' : feachStatusColor(takeoutOrder.status)}
            text={takeoutOrder.isCanceled ? 'キャンセル' : takeoutOrder.status}
            disabled={`${takeoutOrder.isCanceled}` === 'true'}
            onClickEvent={onOpenSelectStatusMenu}
          />
          <SelectStatusMenu
            anchor={selectStatusMenuAnchor}
            onClose={onCloseSelectStatusMenu}
            takeoutOrder={takeoutOrder}
          />
          <RectangleButton
            className={classes.statusButton}
            colorPattern="negative"
            text={takeoutOrder.isCanceled ? 'キャンセル解除' : 'キャンセルする'}
            onClick={toggleCancel}
          />
        </div>
      </div>
    </div>
  );
};
