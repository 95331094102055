import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { CalendarLayout } from '../../../components/takeout/layouts/calendarLayout';
import { takeoutTheme } from '../../../components/takeout/theme/takeoutTheme';
import { BufferContextProvider } from '../../../modules/general/buffer';
import { SocketContextProvider } from '../../../modules/general/socket';
import { TaxContextProvider } from '../../../modules/general/tax';
import { UIContextProvider } from '../../../modules/general/ui';
import { ShopContextProvider } from '../../../modules/takeout/shop';
import { TakeoutOrderContextProvider } from '../../../modules/takeout/takeoutOrder';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const TakeoutCalendarPage: FC = () => {
  const classes = useStyles();

  return (
    <UIContextProvider>
      <SocketContextProvider>
        <BufferContextProvider>
          <TaxContextProvider>
            <TakeoutOrderContextProvider>
              <ShopContextProvider>
                <ThemeProvider theme={takeoutTheme}>
                  <div className={classes.wrapper}>
                    <CalendarLayout />
                  </div>
                </ThemeProvider>
              </ShopContextProvider>
            </TakeoutOrderContextProvider>
          </TaxContextProvider>
        </BufferContextProvider>
      </SocketContextProvider>
    </UIContextProvider>
  );
};

export default TakeoutCalendarPage;
