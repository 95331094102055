import { makeStyles } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { TakeoutOrderContext } from '../../../modules/takeout/takeoutOrder';
import { ClassName } from '../../general/props/classname';
import { ReservationDetailDialogBody } from './reservationDetailDialogBody';
import { ReservationDetailDialogHead } from './reservationDetailDialogHead';
import { TakeoutDialog } from './takeoutDialog';

type Props = ClassName & {
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  paper: {
    '@media (max-width: 1024px)': {
      width: '80%',
    },
    '@media (max-width: 896px)': {
      width: '100%',
    },
  },
}));

export const ReservationDetailDialog: FC<Props> = ({ className, open, onClose }) => {
  const classes = useStyles();

  const takeoutOrderStore = useContext(TakeoutOrderContext);

  return (
    <>
      <TakeoutDialog
        className={classes.paper}
        open={open}
        onClose={onClose}
        width="60%"
        maxWidth="lg"
        height="max-content"
      >
        {takeoutOrderStore.state.selectingOrder && (
          <div className={classes.wrapper}>
            <ReservationDetailDialogHead takeoutOrder={takeoutOrderStore.state.selectingOrder} />
            <ReservationDetailDialogBody takeoutOrder={takeoutOrderStore.state.selectingOrder} />
          </div>
        )}
      </TakeoutDialog>
    </>
  );
};
