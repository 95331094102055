import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { ChangeDisplayWeek } from '../parts/changeDisplayWeek';
import { PickCalenderDate } from '../parts/pickCalendarDate';
import { ReservationSchedule } from '../parts/reservationSchedule';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '95%',
    height: '85%',
  },
}));

export const ReservationCalendar: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <PickCalenderDate />
      <ReservationSchedule />
      <ChangeDisplayWeek />
    </div>
  );
};
