import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  color?: string;
  colorPattern: 'positive' | 'negative' | 'default';
  text: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onClick?: () => void;
  onClickEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.5rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  positive: {
    background: '#8acc9c',
    '&:hover': {
      background: '#75c38a',
    },
  },
  negative: {
    background: '#f6bcbc',
    '&:hover': {
      background: '#fa9f9f',
    },
  },
  default: {
    background: '#b7b7b7',
    '&:hover': {
      background: '#acacac',
    },
  },
  text: {
    color: '#ffffff',
    fontSize: '1rem',
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
    '@media (max-width: 896px)': {
      fontSize: '0.6rem',
    },
    fontWeight: 700,
  },
}));

export const RectangleButton: FC<Props> = ({
  className,
  color,
  colorPattern,
  text,
  disabled,
  autoFocus,
  onClick,
  onClickEvent,
}) => {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.button} ${classes[colorPattern]} ${className}`}
      style={{ background: color }}
      variant="contained"
      disabled={disabled}
      onClick={onClick || onClickEvent}
      autoFocus={autoFocus}
    >
      <Typography className={classes.text} variant="h6">
        {text}
      </Typography>
    </Button>
  );
};
